import './App.css';
import { Route, Routes  } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Landing from './pages/Landing';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import { useEffect, useState } from 'react';
import Community from './pages/Community';
import About from './pages/About';
import FAQ from './pages/FAQ';
import Lip from './pages/Lip';

function App() {
  const [lang, setLang] = useState('en');

  useEffect(()=>{
    const lang = localStorage.getItem('lang');
    if(lang){
      setLang(lang);
    }
  }, [])

  const switcher = (path)=>{
    return (
      <div className="text-center text-white bg-black h-full min-h-svh flex flex-col justify-between items-center">
        <Header lang={lang} setLang={setLang} path={path}/>
        {path===""?<Landing lang={lang}/>: 
        path==="privacy"?<Privacy lang={lang}/>:
        path==="terms"?<Terms lang={lang}/>:
        path==="community"?<Community lang={lang}/>:
        path==="about"?<About lang={lang}/>:
        path==="faq"?<FAQ lang={lang}/>:
        path==="lip"?<Lip lang={lang}/>:
        <p>Page Not Found</p>}
        <Footer lang={lang}/>
      </div>
    )
  }

  return (
      <Routes>
        <Route path="/" element={switcher('')} />
        <Route path="/privacy" element={switcher('privacy')} />
        <Route path="/terms" element={switcher('terms')} />
        <Route path="/community" element={switcher('community')} />
        <Route path="/about" element={switcher('about')} />
        <Route path="/faq" element={switcher('faq')} />
        <Route path="/lip" element={switcher('lip')} />
      </Routes>
  );
}

export default App;
