function Community({lang}) {
  return (
    <>
        {lang==="en" && <div className="text-center flex flex-col justify-between items-center p-12">
        <h1  className="text-xl sm:text-2xl my-6">
        Community Guidelines
        </h1>
        <p className="my-6"><em>Effective Date: 24 January 2024</em></p>

        <p>Welcome to the Bisou community! To ensure a positive and enjoyable experience for all users, we've established these community guidelines. Please familiarize yourself with them before using the app.</p>

        <h2 className="mt-6 mb-4 text-2xl">1. Respect Each Other</h2>
        <p>Respect is the foundation of our community. Treat others with kindness, understanding, and consideration. Harassment, hate speech, or any form of discrimination will not be tolerated.</p>

        <h2 className="mt-6 mb-4 text-2xl">2. Keep it Safe</h2>
        <p>Ensure a safe environment by not sharing personal information, engaging in harmful activities, or promoting violence. Report any behavior that makes you uncomfortable or seems inappropriate.</p>

        <h2 className="mt-6 mb-4 text-2xl">3. Authenticity Matters</h2>
        <p>Be yourself! Use genuine photos and information on your profile. Misleading or deceptive content is not allowed. Authentic connections create a better community for everyone.</p>

        <h2 className="mt-6 mb-4 text-2xl">4. Play Fair</h2>
        <p>Use Bisou for its intended purpose – connecting with others. Avoid spamming, cheating, or engaging in any activity that undermines the integrity of the app.</p>

        <h2 className="mt-6 mb-4 text-2xl">5. Be Mindful of Content</h2>
        <p>Keep content appropriate and respectful. Do not post explicit or offensive materials. Remember that Bisou is used by people of various ages and backgrounds.</p>

        <h2 className="mt-6 mb-4 text-2xl">6. Report Violations</h2>
        <p>If you encounter any user who violates these guidelines or engages in inappropriate behavior, report them immediately. Your input helps maintain a positive community.</p>

        <h2 className="mt-6 mb-4 text-2xl">7. Moderation Actions</h2>
        <p>Bisou reserves the right to take appropriate actions, including warnings, suspensions, or account terminations, in response to violations of these guidelines. Our goal is to create a safe and welcoming space for everyone.</p>

        <h2 className="mt-6 mb-4 text-2xl">8. Stay Informed</h2>
        <p>Keep up to date with any updates or changes to the community guidelines. We may modify them to better serve our growing community and address new challenges.</p>

        <h2 className="mt-6 mb-4 text-2xl">9. Contact Us</h2>
        <p>If you have any questions or concerns about the community guidelines, please contact us at <a className="text-red-600 hover:text-neutral-700" href="mailto:contact@bisou.app">contact@bisou.app</a></p>

        <p>Thank you for contributing to a positive and vibrant Bisou community!</p>

            </div>}
            {lang==="fr" && <div className="text-center flex flex-col justify-between items-center p-12">
                <h1  className="text-xl sm:text-2xl my-6">
                    Règlement de la Communauté
                </h1>
        <p className="my-6"><em>Date d'effet : 24 Janvier 2024</em></p>

        <p>Bienvenue dans la communauté Bisou ! Afin de garantir une expérience positive et agréable pour tous les utilisateurs, nous avons établi ces directives communautaires. Familiarisez-vous avec celles-ci avant d'utiliser l'application.</p>

        <h2 className="mt-6 mb-4 text-2xl">1. Respect Mutuel</h2>
        <p>Le respect est la base de notre communauté. Traitez les autres avec gentillesse, compréhension et considération. Le harcèlement, les discours haineux ou toute forme de discrimination ne seront pas tolérés.</p>

        <h2 className="mt-6 mb-4 text-2xl">2. Sécurité Avant Tout</h2>
        <p>Assurez-vous d'un environnement sûr en ne partageant pas d'informations personnelles, en évitant les activités nuisibles ou la promotion de la violence. Signalez tout comportement qui vous met mal à l'aise ou semble inapproprié.</p>

        <h2 className="mt-6 mb-4 text-2xl">3. L'Authenticité Compte</h2>
        <p>Soyez vous-même ! Utilisez des photos et des informations authentiques sur votre profil. Les contenus trompeurs ou déceptifs ne sont pas autorisés. Les connexions authentiques créent une meilleure communauté pour tous.</p>

        <h2 className="mt-6 mb-4 text-2xl">4. Jouez le Jeu Équitable</h2>
        <p>Utilisez Bisou à des fins prévues - la connexion avec d'autres personnes. Évitez le spam, la tricherie ou toute activité qui compromet l'intégrité de l'application.</p>

        <h2 className="mt-6 mb-4 text-2xl">5. Soyez Prudent avec le Contenu</h2>
        <p>Maintenez un contenu approprié et respectueux. Ne publiez pas de matériel explicite ou offensant. N'oubliez pas que Bisou est utilisé par des personnes de différents âges et horizons.</p>

        <h2 className="mt-6 mb-4 text-2xl">6. Signalez les Violations</h2>
        <p>Si vous rencontrez un utilisateur qui enfreint ces directives ou adopte un comportement inapproprié, signalez-le immédiatement. Votre contribution contribue à maintenir une communauté positive.</p>

        <h2 className="mt-6 mb-4 text-2xl">7. Actions de Modération</h2>
        <p>Bisou se réserve le droit de prendre des mesures appropriées, y compris des avertissements, des suspensions ou des résiliations de compte, en réponse à des violations de ces directives. Notre objectif est de créer un espace sûr et accueillant pour tous.</p>

        <h2 className="mt-6 mb-4 text-2xl">8. Restez Informé</h2>
        <p>Tenez-vous au courant des mises à jour ou des changements aux directives de la communauté. Nous pourrions les modifier pour mieux servir notre communauté croissante et faire face à de nouveaux défis.</p>

        <h2 className="mt-6 mb-4 text-2xl">9. Contactez-Nous</h2>
        <p>Si vous avez des questions ou des préoccupations concernant les directives de la communauté, veuillez nous contacter à <a className="text-red-600 hover:text-neutral-700" href="mailto:contact@bisou.app">contact@bisou.app</a></p>

        <p>Merci de contribuer à une communauté Bisou positive et dynamique !</p>
        </div>}
    </>
  );
}

export default Community;