import logo from '../logo.svg';
import Picker from './Picker';

function Header({lang, setLang, path}) {
  return (
    <div className="text-center flex flex-col justify-center items-center w-full">
      <Picker lang={lang} setLang={setLang}/>
      {path==="" && <div className='flex flex-col justify-center items-center w-full'>
        <img src={logo} className="logo rounded-full animate-pulse" alt="logo" />
        <h1 className="text-6xl sm:text-7xl">Bisou</h1>
      </div>}
      {path!=="" && 
      <a href='/'>
        <div className='flex flex-col justify-center items-center w-full'>
          <img src={logo} className="logo rounded-full animate-pulse" alt="logo" />
          <h1 className="text-6xl sm:text-7xl">Bisou</h1>
        </div>
      </a>}

      
    </div>
  );
}

export default Header;