import { useState } from 'react';

function Picker({lang, setLang}) {
  const [langShown, setLangShown] = useState(false);

  const langs = {
    en: "English",
    fr: "Français"
  }

  const langsArray = (langs)=>{
    let langsArray = [];
    for (let lang in langs) {
      langsArray.push({
        code: lang,
        name: langs[lang]
      });
    }
    return langsArray;
  }

  const chooseLang = (lang)=>{
    setLang(lang);
    setLangShown(false);
    localStorage.setItem('lang', lang);
  }

  return (
    <div className="text-center text-xs flex flex-row justify-end items-center w-full">
      <button
        disabled={langShown}
        className="text-black disabled:bg-black disabled:text-white w-28 hover:text-white hover:bg-black hover:border-white border-1 m-2 bg-white py-2 px-4 rounded-full"
        onClick={()=>setLangShown(!langShown)}>
        {langs[lang]}
      </button>
      {langShown && <div className="text-center flex flex-col justify-between items-center absolute top-12 right-0 z-10">
        {langsArray(langs).map(lang => {
          return (
            <button
              className="text-black w-28 hover:text-white hover:bg-black hover:border-white border-1 m-2 bg-white py-2 px-4 rounded-full"
              onClick={()=>chooseLang(lang.code)}>
              {lang.name}
            </button>
          )
        })}
      </div>}

    </div>
  );
}

export default Picker;