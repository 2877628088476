function Footer({lang}) {
  return (
    <div className='w-full text-xs sm:text-sm flex flex-col md:flex-row justify-center items-center mb-2'>
        <a
          className="text-neutral-300 hover:text-neutral-700 mx-6 my-2"
          href="/about"
          rel="noopener noreferrer"
        >
          {lang==="en"?"About":"À propos"}
        </a>
        <a
          className="text-neutral-300 hover:text-neutral-700 mx-6 my-2"
          href="/FAQ"
          rel="noopener noreferrer"
        >
          FAQ
        </a>

        <a
          className="text-neutral-300 hover:text-neutral-700 mx-6 my-2"
          href="/community"
          rel="noopener noreferrer"
        >
          {lang==="en"?"Community Guidelines":"Règlement"}
        </a>
        <a
          className="text-neutral-300 hover:text-neutral-700 mx-6 my-2"
          href="/privacy"
          rel="noopener noreferrer"
        >
          {lang==="en"?"Privacy Policy":"Politique de confidentialité"}
        </a>
        <a
          className="text-neutral-300 hover:text-neutral-700 mx-6 my-2"
          href="/terms"
          rel="noopener noreferrer"
        >
          {lang==="en"?"Terms & Conditions":"Conditions générales"}
        </a>
        <a
          className="text-neutral-300 hover:text-neutral-700 mx-6 my-2"
          href="mailto:contact@bisou.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact
        </a>
      </div>
  );
}

export default Footer;