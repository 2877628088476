import { useState } from 'react';

function Download({lang}) {
  const [downloadShown, setDownloadShown] = useState(false);

  return (
    <div className="text-center text-xs flex flex-col justify-center items-center w-full">
      <button
        className="text-red-600 text-lg w-60 hover:text-white hover:bg-black hover:border-white border-1 bg-red-600/30 py-2 px-4 rounded-full"
        onClick={()=>setDownloadShown(!downloadShown)}>
        {lang==="en"?"Download App":"Télécharge l'appli"}
      </button>
      {downloadShown && <div className="text-center flex flex-col justify-between items-center mt-2">
        <a 
        href="https://apps.apple.com/app/bisou-app/id6478880866" 
        target='_blank' 
        rel='noreferrer noopener'
        className="text-black text-base w-48 hover:text-white hover:bg-black border-white border-2 m-2 bg-white py-2 px-4 rounded-full justify-center items-center flex"
        >
          iOS
        </a>
        <p className='text-white/70 text-sm'> {lang==="en"?"Android • Coming soon":"Android • Bientôt disponible"}</p>
      </div>}
    </div>
  );
}

export default Download;