import { useState } from "react";

function ToggleBox({title, text, child}) {
    const [shown, setShown] = useState(false);
  return (
    <div className="w-full justify-center items-center flex flex-col">
        <button className="mt-6 mb-4 flex flex-row justify-center items-center" 
            onClick={()=>setShown(!shown)}>
            <h2 className="text-2xl mr-6">{title}</h2>
            <h1 className="text-2xl"> {shown?'-':'+'}</h1>
        </button>
        {shown && <div>
            <p>{text}</p>
            {child}
        </div>}
    </div>
  );
}

export default ToggleBox;