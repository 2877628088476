function Privacy({lang}) {
  return (
    <>
        {lang==="en" && <div className="text-center flex flex-col justify-between items-center p-12">
        <h1  className="text-xl sm:text-2xl my-6">
            Privacy Policy
        </h1>
        <p className="my-6"><em>Effective Date: January 24th</em></p>

        <h2 className="mt-6 mb-4 text-2xl">1. Information We Collect</h2>
        <p>
            <strong>1.1 Account Information:</strong> When you create an account on the Bisou app, we collect information such as your name, email address, date of birth, gender, and preferences.
        </p>
        <p>
            <strong>1.2 Profile Information:</strong> You have the option to provide additional information to enhance your profile, including photos, interests, and a bio.
        </p>
        <p>
            <strong>1.3 Usage Data:</strong> We automatically collect information about your usage of the app, including interactions, matches, and messages. This may include IP addresses, device information, and browser type.
        </p>
        <p>
            <strong>1.4 Location Data:</strong>The Bisou app may collect your location data to show potential matches nearby. You can control location settings in your device.
        </p>
        <p>
            <strong>1.5 Payment Information:</strong> If you choose to subscribe to premium features, we collect payment information, including billing details.
        </p>

        <h2 className="mt-6 mb-4 text-2xl">2. How We Use Your Information</h2>
        <p>
            <strong>2.1 Matchmaking:</strong> We use your information to match you with potential partners based on your preferences, interests, and location.
        </p>
        <p>
            <strong>2.2 Communication:</strong>The Bisou app enables communication between users, and we use your information to facilitate messaging, notifications, and other interactions.
        </p>
        <p>
            <strong>2.3 Improvements:</strong> We analyze user data to improve our app, enhance user experience, and develop new features.
        </p>
        <p>
            <strong>2.4 Security:</strong> We take measures to protect your information from unauthorized access and ensure the security of our platform.
        </p>

        <h2 className="mt-6 mb-4 text-2xl">3. Sharing Your Information</h2>
        <p>
            <strong>3.1 Limited Sharing:</strong> We do not sell your personal information to third parties. Your data is shared only as necessary for the functioning of the app.
        </p>
        <p>
            <strong>3.2 Third-Party Services:</strong>The Bisou app may use third-party services for analytics, advertising, or payment processing. These services have their own privacy policies.
        </p>

        <h2 className="mt-6 mb-4 text-2xl">4. Your Choices</h2>
        <p>
            <strong>4.1 Profile Settings:</strong> You can control the information displayed on your profile and adjust privacy settings in the app.
        </p>
        <p>
            <strong>4.2 Communication Preferences:</strong> Manage your communication preferences, including notifications and messages.
        </p>

        <h2 className="mt-6 mb-4 text-2xl">5. Security</h2>
        <p>
            <strong>5.1 Data Security:</strong> We employ industry-standard security measures to protect your data from unauthorized access.
        </p>
        <p>
            <strong>5.2 Data Retention:</strong> We retain your information only as long as necessary for the purposes outlined in this Privacy Policy.
        </p>

        <h2 className="mt-6 mb-4 text-2xl">6. GDPR, CCPA, and CalOPPA Compliance</h2>
        <p>
            <strong>6.1 GDPR Compliance:</strong>The Bisou app adheres to the General Data Protection Regulation (GDPR) by providing users with the right to access, rectify, erase, and restrict the processing of their personal data.
        </p>
        <p>
            <strong>6.2 CCPA Compliance:</strong> In compliance with the California Consumer Privacy Act (CCPA),the Bisou app recognizes your right to know what personal information is collected, how it's used, and the right to delete that information upon request.
        </p>
        <p>
            <strong>6.3 CalOPPA Compliance:</strong>The Bisou app respects "Do Not Track" signals and provides clear information about third-party tracking, in line with the California Online Privacy Protection Act (CalOPPA).
        </p>

        <h2 className="mt-6 mb-4 text-2xl">7. Children's Privacy</h2>
        <p>The Bisou app is intended for users aged 18 and older. We do not knowingly collect information from individuals under 18 years of age.</p>

        <h2 className="mt-6 mb-4 text-2xl">8. Updates to Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be reflected in the app, and we encourage you to review the policy periodically.</p>

        <h2 className="mt-6 mb-4 text-2xl">9. Contact Us</h2>
        <p>If you have any questions, concerns, or requests regarding your privacy or this Privacy Policy, please contact us at <a className="text-red-600 hover:text-neutral-700" href="mailto:contact@bisou.app">contact@bisou.app</a></p>

        <p>Thank you for choosing the Bisou app. We are committed to providing a safe and enjoyable experience for our users.</p>

        </div>}
        {lang==="fr" && <div className="text-center flex flex-col justify-between items-center p-12">
            <h1  className="text-xl sm:text-2xl my-6">
                Politique de confidentialité
            </h1>
            <p className="my-6"><em>Date d'entrée en vigueur : 24 janvier 2024</em></p>
        
            <h2 className="mt-6 mb-4 text-2xl">1. Informations que nous collectons</h2>
            <p>
                <strong>1.1 Informations du compte :</strong> Lorsque vous créez un compte Bisou, nous collectons des informations telles que votre nom, votre adresse e-mail, votre date de naissance, votre genre et vos préférences.
            </p>
            <p>
                <strong>1.2 Informations de profil :</strong> Vous avez la possibilité de fournir des informations supplémentaires pour enrichir votre profil, y compris des photos, des centres d'intérêt et une biographie.
            </p>
            <p>
                <strong>1.3 Données d'utilisation :</strong> Nous collectons automatiquement des informations sur votre utilisation de l'application, y compris les interactions, les correspondances et les messages. Cela peut inclure des adresses IP, des informations sur l'appareil et le type de navigateur.
            </p>
            <p>
                <strong>1.4 Données de localisation :</strong> Bisou peut collecter vos données de localisation pour afficher des correspondances potentielles à proximité. Vous pouvez contrôler les paramètres de localisation sur votre appareil.
            </p>
            <p>
                <strong>1.5 Informations de paiement :</strong> Si vous choisissez de souscrire à des fonctionnalités premium, nous collectons des informations de paiement, y compris les détails de facturation.
            </p>

            <h2  className="mt-6 mb-4 text-2xl">2. Comment nous utilisons vos informations</h2>
            <p>
                <strong>2.1 Mise en relation :</strong> Nous utilisons vos informations pour vous mettre en relation avec des partenaires potentiels en fonction de vos préférences, de vos centres d'intérêt et de votre emplacement.
            </p>
            <p>
                <strong>2.2 Communication :</strong> Bisou permet la communication entre les utilisateurs, et nous utilisons vos informations pour faciliter les messages, les notifications et d'autres interactions.
            </p>
            <p>
                <strong>2.3 Améliorations :</strong> Nous analysons les données des utilisateurs pour améliorer notre application, améliorer l'expérience utilisateur et développer de nouvelles fonctionnalités.
            </p>
            <p>
                <strong>2.4 Sécurité :</strong> Nous prenons des mesures pour protéger vos informations contre tout accès non autorisé et assurer la sécurité de notre plateforme.
            </p>

            <h2  className="mt-6 mb-4 text-2xl">3. Partage de vos informations</h2>
            <p>
                <strong>3.1 Partage limité :</strong> Nous ne vendons pas vos informations personnelles à des tiers. Vos données ne sont partagées que dans la mesure nécessaire au fonctionnement de l'application.
            </p>
            <p>
                <strong>3.2 Services tiers :</strong> Bisou peut utiliser des services tiers pour l'analyse, la publicité ou le traitement des paiements. Ces services ont leurs propres politiques de confidentialité.
            </p>

            <h2  className="mt-6 mb-4 text-2xl">4. Vos choix</h2>
            <p>
                <strong>4.1 Paramètres du profil :</strong> Vous pouvez contrôler les informations affichées sur votre profil et ajuster les paramètres de confidentialité dans l'application.
            </p>
            <p>
                <strong>4.2 Préférences de communication :</strong> Gérez vos préférences de communication, y compris les notifications et les messages.
            </p>

            <h2  className="mt-6 mb-4 text-2xl">5. Sécurité</h2>
            <p>
                <strong>5.1 Sécurité des données :</strong> Nous utilisons des mesures de sécurité conformes aux normes de l'industrie pour protéger vos données contre tout accès non autorisé.
            </p>
            <p>
                <strong>5.2 Conservation des données :</strong> Nous conservons vos informations uniquement aussi longtemps que nécessaire aux fins décrites dans cette politique de confidentialité.
            </p>

            <h2  className="mt-6 mb-4 text-2xl">6. Conformité au GDPR, CCPA et CalOPPA</h2>
            <p>
                <strong>6.1 Conformité au GDPR :</strong> Bisou se conforme au Règlement Général sur la Protection des Données (GDPR), garantissant le respect et la protection de vos droits en matière de données.
            </p>
            <p>
                <strong>6.2 Conformité au CCPA :</strong> En conformité avec la California Consumer Privacy Act (CCPA), Bisou reconnaît et respecte les droits des résidents de Californie en matière de contrôle sur leurs informations personnelles.
            </p>
            <p>
                <strong>6.3 Conformité au CalOPPA :</strong> Conformément à la California Online Privacy Protection Act (CalOPPA), Bisou respecte les signaux "Do Not Track" et fournit des informations claires sur le suivi par des tiers.
            </p>

            <h2  className="mt-6 mb-4 text-2xl">7. Confidentialité des enfants</h2>
            <p>L'application Bisou est destinée aux utilisateurs âgés de 18 ans et plus. Nous ne collectons pas sciemment d'informations auprès des personnes de moins de 18 ans.</p>

            <h2  className="mt-6 mb-4 text-2xl">8. Mises à jour de la politique de confidentialité</h2>
            <p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Toutes les modifications seront reflétées dans l'application, et nous vous encourageons à revoir la politique périodiquement.</p>

            <h2  className="mt-6 mb-4 text-2xl">9. Contactez-nous</h2>
            <p>Si vous avez des questions, des préoccupations ou des demandes concernant votre vie privée ou cette politique de confidentialité, veuillez nous contacter à l'adresse <a className="text-red-600 hover:text-neutral-700" href="mailto:contact@bisou.app">contact@bisou.app</a></p>

            <p>Merci d'avoir choisi Bisou. Nous nous engageons à fournir une expérience sûre et agréable pour nos utilisateurs.</p>
        </div>}
    </>
  );
}

export default Privacy;