import ToggleBox from "../components/ToggleBox";

function  FAQ({lang}) {

  const questions =  {
    en: [
      {
        question: "How does Bisou work?",
        answer: "Bisou is a dating app that connects people based on their interests and location. To start matching with people, you need to fill in your profile and post a Story."
      },
      {
        question: "What is a Story?",
        answer: "A Story is a public photo that you post to your profile. Your Story will be visible to others in your area and they can react to it for 24 hours. You can only have one active Story at a time. When someone likes your Story, you earn a BI$OU. You cannot swipe or match with people without an active Story."
      },
      {
        question: "What is a BI$OU?",
        answer: "A BI$OU is a virtual currency that you can earn and spend. You can earn BI$OUs when people like your public Story or when chatting with your Connections. You can spend BI$OUs to save messages in the chats. In the future, BI$OUs will unlock additional features and benefits."
      },
      {
        question: "What is a Connection?",
        answer: "A Connection is formed when two people like each other's Story and send each other BI$OUs. After a Connection is formed, you can start chatting with the person you matched with."
      },
      {
        question: "How to earn more?",
        answer: "Read messages disappear after 24 hours unless they are saved. When someone saves your messages, you earn the BI$OUs they spent."
      },
      {
        question: "What is VIP status?",
        answer: "You gain VIP status when you reach 1,000 BI$OUs. VIP status is symbolised by a golden star. Your status is only visible to your Connections. In the future, VIP status will unlock additional features and benefits."
      },
    ],
    fr: [
      {
        question: "Comment fonctionne Bisou?",
        answer: "Bisou est une application de rencontre qui connecte les gens en fonction de leurs intérêts et de leur géolocalisation. Pour commencer à matcher avec des gens, tu dois remplir ton profil et publier une Story."
      },
      {
        question: "Qu'est-ce qu'une Story?",
        answer: "Une Story est une photo publique et géolocalisée que tu publies sur ton profil. Ta Story sera visible par d'autres personnes de ta région et elles pourront y réagir pendant 24 heures. Tu ne peux avoir qu'une seule Story active à la fois. Lorsque quelqu'un aime ta Story, tu gagnes un BI$OU. Tu ne peux pas swiper ou matcher avec des gens sans une Story active."
      },
      {
        question: "Qu'est-ce qu'un BI$OU?",
        answer: "Un BI$OU est une monnaie virtuelle que tu peux gagner et dépenser. Tu peux gagner des BI$OUS lorsque les gens aiment ta Story publique ou lorsque tu discutes avec tes Connections. Tu peux dépenser des BI$OUS pour sauvegarder des messages dans les chats. À l'avenir, les BI$OUS débloqueront des fonctionnalités et des avantages supplémentaires."
      },
      {
        question: "Qu'est-ce qu'une Connection?",
        answer: "Une Connection se forme lorsque deux personnes aiment la Story de l'autre et s'envoient des BI$OUs. Après qu'une Connection est formée, tu peux commencer à discuter avec la personne avec qui tu as matché."
      },
      {
        question: "Comment gagner plus?",
        answer: "Les messages lus disparaissent après 24 heures sauf s'ils sont sauvegardés. Lorsque quelqu'un sauvegarde tes messages, tu gagnes les BI$OUS que la personne a dépensé."
      },
      {
        question: "Qu'est-ce que le statut VIP?",
        answer: "Tu obtiens le statut VIP lorsque tu atteins 1 000 BI$OUS. Le statut VIP est symbolisé par une étoile dorée. Ton statut n'est visible que par tes Connections. À l'avenir, le statut VIP débloquera des fonctionnalités et des avantages supplémentaires."
      },
    ]
  }

  


  return (
    <div className="text-center flex flex-col justify-between items-center p-12">
      <h1  className="text-xl sm:text-2xl my-6">
      {lang==="en"?"Frequently asked questions": "Foire aux questions"}
      </h1>
      {questions[lang].map((q, i)=>{
        return <ToggleBox 
          key={i}
          title={q.question}
          text={q.answer}
        />
      })}

    </div>
  );
}

export default FAQ;