import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import Firebase from '../firebase';

function Download({lang, cancel}) {
  const [email, setEmail] =useState();
  const [error, setError] =useState('');
  const [joined, setJoined] =useState(false);
  const [loading, setLoading] =useState(false);
  const db = getFirestore(Firebase);

  
  const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const registerEmail = async ()=>{
    setLoading(true);
    if(!email) {
      setError('No email');
    } else {
      if (!isEmail(email)){
        setError(lang==="en"? 'Invalid Email': 'Email invalide');
      } else {
        const city = await getCityFromIp();
        await setDoc(doc(db, 'emails', email), {
          email: email,
          city: city,
          lang: lang,
          time: serverTimestamp(),
          invited: false,
        }).then(()=>{
          setJoined(true);
          setError(''); 
        }).catch((e)=>{
          if (e.code === 'permission-denied' || e.code === 'already-exists') {
            setError(lang==="en"? 'Already subscribed.': 'Déjà inscrit.');
          }
          else { 
            setError(lang==="en"? 'An error occured. Try again later.': 'Une erreur est survenue. Réessaie plus tard.'); 
          }
        })
      }
    }
    setLoading(false);
  }

  const getIp = async () => {
    const res = await fetch('https://api64.ipify.org?format=json');
    const data = await res.json();
    console.log(data);
    if (data.ip) {
      console.log(data.ip);
      return data.ip;
    }
  }

  const getCityFromIp = async () => {
    const ip = await getIp();
    if (!ip) return 'Unknown';
    const res = await fetch(`https://ipapi.co/${ip}/json/`);
    const data = await res.json();
    if (data.city) {
      console.log(data.city);
      return data.city;
    } else {
      return 'Unknown';
    }
  }


  return (
    <div className="text-center text-xs flex flex-col justify-center items-center w-full">
      {!joined && <div>
        <div className='text-sm w-full sm:text-base flex flex-col bg-black justify-center items-center'>
          <input 
            value={email}
            onChange={(e) => {setEmail(e.target.value); setError('')}}
            type="email"
            placeholder="Email"
            className="w-72 text-center placeholder-neutral-400 text-white bg-black border-white border-l-2 border-r-2 my-2 py-2 px-6 rounded-full"
          />
          {!loading && <div className="text-center flex flex-col justify-center items-center">
            <button
              disabled={!email || loading || joined}
              className="w-36 text-black hover:text-white hover:bg-black hover:border-white my-2 bg-white py-2 px-6 rounded-full"
              onClick={registerEmail}>
              {lang==="en"? "Subscribe":"S'abonner"}
            </button>
            <button
              className="w-36 text-white hover:text-white hover:bg-black hover:border-white my-2 bg-white/30 py-2 px-6 rounded-full"
              onClick={()=>{
                cancel();
                setEmail('');
                setError('');
              }}>
              {lang==="en"? "Cancel":"Annuler"}
            </button>
            
          </div>}
          {loading && <p className="text-sm sm:text-base text-white h-12">
            {lang==="en"?"A moment...":"Un instant..."}
          </p>}
        </div>
        <p className="text-sm sm:text-base text-red-600 h-12">{error}</p>
      </div>}
      {joined && <div className="h-36">
        <h1 className="text-xl sm:text-2xl mb-6">
          {lang==="en"?"Thank you":"Merci"}
        </h1>
        <p className="text-base sm:text-lg mb-6">
          {lang==="en"?"You're on the waitlist. We'll drop you a message soon.":"T'as bien été inscrit sur la liste d'attente. On te tient au courant."}
        </p>
      </div>}
    </div>
  );
}

export default Download;