import { useState } from 'react';
import Download from '../components/Download';
import Subscribe from '../components/Subscribe';

function Landing({lang}) {
  const [subShown, setSubShown] = useState(false);

  return (
    <div className="text-center h-72 flex flex-col justify-start items-center w-full">
      <h1 className="text-xl sm:text-2xl mb-6">
        {lang==="fr"?"T'en veux ?":"Want some ?"}
      </h1>
      {!subShown && <Download lang={lang}/>}
      {!subShown && <button
        className="text-white text-base w-60 hover:text-white hover:bg-black hover:border-white border-1 mt-6 bg-white/30 py-2 px-4 rounded-full"
        onClick={()=>setSubShown(true)}>
        {lang==="en"?"Join the list":"Rejoins la liste"}
      </button>}
      {subShown && <Subscribe lang={lang} cancel={()=>setSubShown(false)}/>}
    </div>
  );
}

export default Landing;