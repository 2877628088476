function  About({lang}) {

  const paragraphs= {
    "en": {
      "par1": "Swiping right on faces that look like they belong on a billboard but feeling zero sparks? Yeah, we've all been there. Dating apps these days are a desert of thirst traps and bios that read like grocery lists.  You put yourself out there, hoping someone sees the real you under all the filters, but deep down it just feels like playing a game.",
      "par2": "But what if there was an app that was all about the raw connection? Imagine a place where you can ditch the pretense and find someone who gets your vibe, your humor, the real you that shines through. That's Bisou, baby. Bisou isn't just about finding a date, it's about finding your partner-in-crime and getting rewarded for it.",
    },
    "fr": {
      "par1": "Marre de swiper à droite sur des profils dignes de mannequins mais sans ressentir aucune étincelle ? Ouais, on est tous passés par là. Les applications de rencontre de nos jours sont un désert de photos ultra-retouchées et de bios qui ressemblent à des listes de courses. Tu te mets en avant, en espérant que quelqu'un verra ton véritable toi sous tous ces filtres, mais au fond, on a l'impression de jouer un jeu.",
      "par2": "Et si on te disait qu'il existe une appli qui mise tout sur la connexion brute et authentique ? Imagine un endroit où tu peux lâcher prise et trouver quelqu'un qui capte ton délire, ton humour, le vrai toi qui rayonne. C'est Bisou, bébé.  Avec Bisou, ne cherche pas juste un date, mais une vraie complicité, et en plus, tu peux être récompensé pour ça.",
    }
  }




  return (
    <div className="text-center flex flex-col justify-between items-center p-12">
      <h1  className="text-xl sm:text-2xl my-6">{lang==="en"?"About":"À propos"}</h1>
      <p className="my-6"><em>{lang==="en"?"Last update: 24 March 2024":"Dernière mise à jour: 24 Mars 2024"}</em></p>
      <p className="my-2">{paragraphs[lang].par1}</p>
      <p className="my-2">{paragraphs[lang].par2}</p>
      <p className="my-2">{paragraphs[lang].par3}</p>
      <p className="my-2">{paragraphs[lang].par4}</p>
      <p className="my-2">{paragraphs[lang].par5}</p>
      <p className="my-2">{paragraphs[lang].par6}</p>
      <p className="my-2">{paragraphs[lang].par7}</p>
      <p className="my-2">{paragraphs[lang].par8}</p>

    </div>
    
  );
}

export default About;