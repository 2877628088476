
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCwLefKw921bJuYeuz8OBd2Zapl-Df4yCM",
    authDomain: "bisou-cc7ec.firebaseapp.com",
    projectId: "bisou-cc7ec",
    storageBucket: "bisou-cc7ec.appspot.com",
    messagingSenderId: "686748209413",
    appId: "1:686748209413:web:6f97c3e858a1b195e1676c"
};

const Firebase = initializeApp(firebaseConfig);


export default Firebase;