function  Lip({lang}) {


  const paragraphs= {
    "en": {
      "par1": "To mark Bisou's launch, we're introducing Lovers In Paris (LIP), an exclusive collection of 1,024 unique NFTs celebrating Bisou's birthplace, Paris.",
      "par2": "LIP will be gradually revealed on our social media channels. The collection will be available for minting starting June 15th, 2024, at 9:00 AM UTC.",
    },
    "fr": {
      "par1": "Pour célébrer le lancement de Bisou, nous avons créé Lovers In Paris (LIP), une collection exclusive de 1 024 NFTs uniques célébrant le lieu de naissance de Bisou, Paris.",
      "par2": "LIP sera progressivement révélée sur nos réseaux sociaux. La collection pourra être mintée à partir du 15 juin 2024, à 9h00 UTC.",
    }
  }


  return (
    <div className="text-center flex flex-col justify-between items-center p-12">
      <img src="/lip.jpg" alt="Lovers In Paris" className="w-64 rounded-3xl"/>
      <h1  className="text-xl sm:text-2xl my-6">Lovers In Paris (LIP)</h1>
      <p className="my-2">
        {paragraphs[lang].par1}<br></br>
        {paragraphs[lang].par2}<br></br>
      </p>
      <div className="flex flex-row justify-center items-center">
        <a href="https://twitter.com/bisou_app" 
          className="hover:opacity-30"
          target="_blank" 
          rel="noopener noreferrer">
          <img src="/x.svg" alt="X (Formerly twitter)" className="w-8 mx-2"/>
        </a>
        <a href="https://instagram.com/bisou.app" 
        className="hover:opacity-30"
          target="_blank" 
          rel="noopener noreferrer">
          <img src="/ig.svg" alt="Instagram" className="w-8 mx-2"/>
        </a>
        <a href="https://tiktok.com/@bisou.app" 
          className="hover:opacity-30"
          target="_blank" 
          rel="noopener noreferrer">
          <img src="/tt.svg" alt="Tiktok" className="w-8 mx-2"/>
        </a>
      </div>
    </div>
  );
}

export default Lip;