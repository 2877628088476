function Terms({lang}) {
  return (
    <>
        {lang==="en" && <div className="text-center flex flex-col justify-between items-center p-12">
            <h1  className="text-xl sm:text-2xl my-6">Terms & Conditions</h1>
            <p className="my-6"><em>Effective Date: January 24th</em></p>
            <p>By using the The Bisou App App, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using the app.</p>
            <h2 className="mt-6 mb-4 text-2xl">1. Acceptance of Terms</h2>
            <p>
                By accessing or using The Bisou App, you agree to be bound by these Terms and Conditions. If you do not agree to all the terms and conditions, you may not use the app.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">2. Use of the App</h2>
            <p>
                The Bisou App is intended for personal, non-commercial use. You may not use the app for any illegal or unauthorized purpose.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">3. User Accounts</h2>
            <p>
                You are responsible for maintaining the confidentiality of your account and password. You agree to notify The Bisou App immediately of any unauthorized use of your account.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">4. User Content</h2>
            <p>
                Users may post content, including photos and text. By posting content on The Bisou App, you grant us a non-exclusive, royalty-free, worldwide, and fully sub-licensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">5. Prohibited Conduct</h2>
            <p>
                You agree not to engage in any conduct that restricts or inhibits any other user from using or enjoying the app. Prohibited conduct includes, but is not limited to, harassment, stalking, or any illegal activity.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">6. Termination</h2>
            <p>
                The Bisou App reserves the right to terminate or suspend your account and access to the app for any reason, including violation of these Terms and Conditions.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">7. Privacy</h2>
            <p>
                Your use of The Bisou App is also governed by our Privacy Policy. Please review the Privacy Policy to understand our practices.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">8. Changes to Terms and Conditions</h2>
            <p>
                The Bisou App reserves the right to update or change these Terms and Conditions at any time. Your continued use of the app after we post any modifications constitutes acceptance of those changes.
            </p>
            <h2 className="mt-6 mb-4 text-2xl">9. Contact Us</h2>
            <p>If you have any questions about these Terms and Conditions, please contact us at <a className="text-red-600 hover:text-neutral-700" href="mailto:contact@bisou.app">contact@bisou.app</a></p>
            <p>Thank you for using The Bisou App. We hope you enjoy your experience!</p>
        </div>}
        {lang==="fr" && <div className="text-center flex flex-col justify-between items-center p-12">
            <h1  className="text-xl sm:text-2xl my-6">Conditions générales</h1>
            <p className="my-6"><em>Date d'entrée en vigueur : 24 janvier 2024</em></p>
            <p>En utilisant l'application Bisou, vous acceptez de respecter et d'être lié par les conditions générales suivantes. Veuillez lire attentivement ces conditions avant d'utiliser l'application.</p>

            <h2 className="mt-6 mb-4 text-2xl">1. Acceptation des conditions</h2>
            <p>
                En accédant ou en utilisant Bisou, vous acceptez d'être lié par ces Conditions Générales. Si vous n'acceptez pas toutes les conditions, vous ne pouvez pas utiliser l'application.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">2. Utilisation de l'application</h2>
            <p>
                Bisou est destiné à un usage personnel et non commercial. Vous ne pouvez pas utiliser l'application à des fins illégales ou non autorisées.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">3. Comptes Utilisateur</h2>
            <p>
                Vous êtes responsable de maintenir la confidentialité de votre compte et de votre mot de passe. Vous acceptez de notifier Bisou immédiatement de toute utilisation non autorisée de votre compte.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">4. Contenu Utilisateur</h2>
            <p>
                Les utilisateurs peuvent publier du contenu, y compris des photos et du texte. En publiant du contenu sur Bisou, vous nous accordez une licence non exclusive, libre de redevances, mondiale et entièrement cessible pour utiliser, reproduire, modifier, adapter, publier, traduire, créer des œuvres dérivées, distribuer et afficher ce contenu.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">5. Comportement Interdit</h2>
            <p>
                Vous acceptez de ne pas vous engager dans un comportement qui restreint ou inhibe tout autre utilisateur d'utiliser ou de profiter de l'application. Le comportement interdit inclut, mais ne se limite pas à, le harcèlement, la traque ou toute activité illégale.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">6. Résiliation</h2>
            <p>
                Bisou se réserve le droit de résilier ou de suspendre votre compte et l'accès à l'application pour toute raison, y compris la violation de ces Conditions Générales.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">7. Confidentialité</h2>
            <p>
                Votre utilisation de Bisou est également régie par notre Politique de Confidentialité. Veuillez consulter la Politique de Confidentialité pour comprendre nos pratiques.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">8. Modification des Conditions Générales</h2>
            <p>
                Bisou se réserve le droit de mettre à jour ou de modifier ces Conditions Générales à tout moment. Votre utilisation continue de l'application après la publication de toute modification constitue une acceptation de ces changements.
            </p>

            <h2 className="mt-6 mb-4 text-2xl">9. Contactez-nous</h2>
            <p>Si vous avez des questions sur ces Conditions Générales, veuillez nous contacter à l'adresse <a className="text-red-600 hover:text-neutral-700" href="mailto:contact@bisou.app">contact@bisou.app</a></p>

            <p>Merci d'utiliser Bisou. Nous espérons que vous apprécierez votre expérience !</p>
        
        </div>}
    </>
  );
}

export default Terms;